import React from "react";
import { LinearProgress, TextField, MenuItem } from "@mui/material";

import { FormToolbar, flattenRules } from "@homesusa/form";
import { MarketCodeLabel, RoleEmployee } from "@homesusa/core";
import { useHasRole } from "@homesusa/auth";
import { useDocumentTitle } from "@homesusa/layout";
import { CompanyContext } from "../contexts";
import {
  CompanySectionForm,
  CompanySectionFormIcon,
  CompanySectionFormLabel,
} from "core/enums";
import { LabelSections } from "core/components";
import { CompanyFormProvider } from "../providers";
import { FormUpdateComponent } from "./form-sections";
import { EmployeesGrid } from "./employee/employees-grid.component";
import { CompanyServicesGrid } from "./form-sections/company-services/service-grid.component";
import { CompanyActionsGroup } from "./company-actions-group";
import { companyDetailsRules } from "../rules";
import { SavedChangesGrid } from "./saved-changes/saved-changes.component";

function CompanyFormPanel(): JSX.Element {
  const { company } = React.useContext(CompanyContext);
  const [activeSection, setActiveSection] = React.useState<CompanySectionForm>(
    CompanySectionForm.information
  );
  const title = React.useMemo(
    () => `${company.name} - ${MarketCodeLabel.get(company.market)}`,
    [company.name, company.market]
  );
  useDocumentTitle(`Company - ${title}`);

  const { hasEmployeeRole, isMlsAdministrator } = useHasRole();

  const sections = React.useMemo(
    () =>
      isMlsAdministrator ||
      hasEmployeeRole([
        RoleEmployee.CompanyAdmin,
        RoleEmployee.CompanyAdminReadonly,
      ])
        ? Object.values(CompanySectionForm)
        : Object.values(CompanySectionForm).filter(
            (value) => value != CompanySectionForm.employees
          ),
    [hasEmployeeRole]
  );

  if (!company) {
    return <LinearProgress />;
  }

  return (
    <CompanyFormProvider
      initialData={company}
      rules={flattenRules(companyDetailsRules)}
    >
      <FormToolbar>
        <FormToolbar.Left title={title} />
        {activeSection == CompanySectionForm.information && (
          <FormToolbar.Right>
            <CompanyActionsGroup />
          </FormToolbar.Right>
        )}
      </FormToolbar>
      <TextField
        select
        variant="standard"
        sx={{ width: "250px", mb: 2 }}
        onChange={(e): void => {
          setActiveSection(e.target.value as CompanySectionForm);
        }}
        value={activeSection}
      >
        {sections.map((option) => (
          <MenuItem key={option} value={option}>
            <LabelSections
              text={CompanySectionFormLabel.get(option) ?? ""}
              icon={CompanySectionFormIcon.get(option)}
            />
          </MenuItem>
        ))}
      </TextField>
      {activeSection == CompanySectionForm.information && (
        <FormUpdateComponent />
      )}
      {activeSection == CompanySectionForm.employees && <EmployeesGrid />}
      {activeSection == CompanySectionForm.subscription && (
        <CompanyServicesGrid />
      )}
      {activeSection == CompanySectionForm.historyChanges && (
        <SavedChangesGrid />
      )}
    </CompanyFormProvider>
  );
}

export default CompanyFormPanel;
