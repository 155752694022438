import { CompanySummaryInformation } from "core/interfaces";
import { CompanySavedChangesContext } from "../contexts";
import React from "react";
import { FieldType, toYesNo, useGetFieldInfo } from "@homesusa/record";
import { toUpperCamelCase } from "../utils/upper-camel-case.utils";
import { Format } from "@homesusa/core";

export const useCompanySummaryMapping = (): CompanySummaryInformation => {
  const { summary } = React.useContext(CompanySavedChangesContext);
  const { defaultFieldInfo } = useGetFieldInfo(summary);

  const summaryInfo: CompanySummaryInformation = React.useMemo(
    () => ({
      general: {
        information: [
          defaultFieldInfo("CompanyHistory", "Name"),
          defaultFieldInfo("CompanyHistory", "CompanyType"),
          defaultFieldInfo("CompanyHistory", "BrokerId"),
          defaultFieldInfo("CompanyHistory", "Domain"),
          { ...defaultFieldInfo("CompanyHistory", "WebUrl"), size: 12 },
          defaultFieldInfo("AddressInfo", "StreetNum"),
          defaultFieldInfo("AddressInfo", "StreetName"),
          defaultFieldInfo("AddressInfo", "City"),
          defaultFieldInfo("AddressInfo", "State", (value): string =>
            value.toUpperCase()
          ),
          defaultFieldInfo("AddressInfo", "Zip"),
        ],
        presentation: [
          defaultFieldInfo("SettingInfo", "HideLeadManager", (value): string =>
            toYesNo(value)
          ),
          defaultFieldInfo(
            "SettingInfo",
            "DisplayDeletedListingsWindow",
            (value): string => toYesNo(value)
          ),
          defaultFieldInfo("SettingInfo", "AllowNewListings", (value): string =>
            toYesNo(value)
          ),
          defaultFieldInfo(
            "SettingInfo",
            "ShowRealtorReports",
            (value): string => toYesNo(value)
          ),
          defaultFieldInfo(
            "SettingInfo",
            "AllowPhotoRequestForIncompleteHomes",
            (value): string => toYesNo(value)
          ),
          defaultFieldInfo(
            "SettingInfo",
            "StopXMLMediaSyncOfExistingListings",
            (value): string => toYesNo(value)
          ),
          defaultFieldInfo(
            "SettingInfo",
            "OnlyPullHomesToList",
            (value): string => toYesNo(value)
          ),
          defaultFieldInfo(
            "SettingInfo",
            "StopCompletedHomesWithOutPhotosEmails",
            (value): string => toYesNo(value)
          ),
          defaultFieldInfo("SettingInfo", "StopAlertEmails", (value): string =>
            toYesNo(value)
          ),
          defaultFieldInfo("SettingInfo", "HideExpiredDate", (value): string =>
            toYesNo(value)
          ),
          defaultFieldInfo(
            "SettingInfo",
            "LockListingsForSpecDeckOnboarding",
            (value): string => toYesNo(value)
          ),
          defaultFieldInfo(
            "SettingInfo",
            "ShowSellingAgentInternalReport",
            (value): string => toYesNo(value)
          ),
        ],
        contact: [defaultFieldInfo("CompanyHistory", "CustomerRef")],
        FTP: [
          defaultFieldInfo("PhotoRequestInfo", "AutoFtp", (value): string =>
            toYesNo(value)
          ),
          defaultFieldInfo("PhotoRequestInfo", "FtpUrl"),
          defaultFieldInfo(
            "PhotoRequestInfo",
            "TransferProtocol",
            (value): string => value.toUpperCase()
          ),
          defaultFieldInfo("PhotoRequestInfo", "FtpUsername"),
          defaultFieldInfo("PhotoRequestInfo", "FtpPassword"),
        ],
        broker: [
          defaultFieldInfo("BrokerInfo", "LicenseNumber"),
          defaultFieldInfo("BrokerInfo", "SiteUsername"),
          defaultFieldInfo("BrokerInfo", "SitePassword"),
          defaultFieldInfo("BrokerInfo", "SiteLoginURL"),
          defaultFieldInfo("BrokerInfo", "AgentUID"),
          defaultFieldInfo("BrokerInfo", "OfficeUID"),
        ],
      },
      photoRequest: {
        changes: [
          defaultFieldInfo(
            "PhotoRequestInfo",
            "IsCentralized",
            (value): string => toYesNo(value)
          ),
          defaultFieldInfo("PhotoRequestInfo", "ResizePhotos"),
          defaultFieldInfo("PhotoRequestInfo", "CarbonCopyEmails"),
          defaultFieldInfo(
            "PhotoRequestInfo",
            "IsCentralizedPhotoWarningEmail",
            (value): string => toYesNo(value)
          ),
          defaultFieldInfo("PhotoRequestInfo", "BackupPhone"),
          defaultFieldInfo("PhotoRequestInfo", "PhotoGuidelines"),
          {
            ...defaultFieldInfo("PhotoRequestInfo", "PhotoManagerName"),
            fieldName: "photoRequestInfo.photoManagerId",
          },
        ],
      },
      mls: {
        general: [
          defaultFieldInfo("CompanyHistory", "ViolationWarningEmail"),
          defaultFieldInfo(
            "PhoneLeadInfo",
            "IsCentralizedForSale",
            (value): string => toYesNo(value)
          ),
          defaultFieldInfo(
            "PhoneLeadInfo",
            "CentralizeLeadPhone",
            Format.PhoneNumber
          ),
          defaultFieldInfo(
            "PhoneLeadInfo",
            "IsCentralizedForLease",
            (value): string => toYesNo(value)
          ),
          defaultFieldInfo(
            "PhoneLeadInfo",
            "CentralizeLeadPhoneForLease",
            Format.PhoneNumber
          ),
          defaultFieldInfo(
            "PhoneLeadInfo",
            "IsCentralizedForLot",
            (value): string => toYesNo(value)
          ),
          defaultFieldInfo(
            "PhoneLeadInfo",
            "CentralizeLeadPhoneForLot",
            Format.PhoneNumber
          ),
          defaultFieldInfo(
            "EmailLeadInfo",
            "LockedEmailLeads",
            (value): string => toYesNo(value)
          ),
          defaultFieldInfo("MlsInfo", "IncludeMls", (value): string =>
            toYesNo(value)
          ),
          defaultFieldInfo("MlsInfo", "BlockSquareFootage", (value): string =>
            toYesNo(value)
          ),
          defaultFieldInfo("MlsInfo", "RemarksForCompletedHomes"),
          defaultFieldInfo("MlsInfo", "BuyerAgentCommission"),
          {
            ...defaultFieldInfo(
              "MlsInfo",
              "BuyerAgentCommissionType",
              (value): string => toUpperCamelCase(value)
            ),
            type: FieldType.Multiple,
          },
          defaultFieldInfo("ContactInfo", "EmailRealtorsContact"),
          {
            ...defaultFieldInfo("ContactInfo", "PrimaryContactName"),
            fieldName: "contactInfo.primaryContactId",
          },
          {
            ...defaultFieldInfo("ContactInfo", "AccountingContactName"),
            fieldName: "contactInfo.accountingContactId",
          },
        ],
      },
      photography: {
        changes: [
          defaultFieldInfo("PhotographyServiceInfo", "StillHome"),
          defaultFieldInfo("PhotographyServiceInfo", "StillExteriorOnly"),
          {
            ...defaultFieldInfo(
              "PhotographyServiceInfo",
              "IsFirstSetIncluded",
              (value): string => toYesNo(value)
            ),
          },
          defaultFieldInfo("PhotographyServiceInfo", "Twilight"),
          defaultFieldInfo("PhotographyServiceInfo", "VirtualTour"),
          defaultFieldInfo("PhotographyServiceInfo", "VirtualStagingOneImage"),
          defaultFieldInfo(
            "PhotographyServiceInfo",
            "VirtualStagingThreeImage"
          ),
          defaultFieldInfo("PhotographyServiceInfo", "VirtualStagingSixImage"),
          defaultFieldInfo("PhotographyServiceInfo", "CommunityOptionOne"),
          defaultFieldInfo("PhotographyServiceInfo", "CommunityOptionTwo"),
          defaultFieldInfo("PhotographyServiceInfo", "CommunityOptionThree"),
          defaultFieldInfo(
            "PhotographyServiceInfo",
            "PhotographyServicesEnabled",
            (value): string => toYesNo(value)
          ),
          defaultFieldInfo("PhotographyServiceInfo", "TripCharge"),
          defaultFieldInfo("PhotographyServiceInfo", "PhotoshopEditing"),
        ],
      },
    }),
    []
  );

  return summaryInfo;
};
