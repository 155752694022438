import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { LinearProgress } from "@mui/material";

import { ErrorBoundary, RoleEmployee, RoleUser } from "@homesusa/core";
import {
  AppContext,
  EmployeePrivateRoute,
  PrivateRoute,
  appPrivateRoutesConfig,
  appPublicRoutesConfig,
} from "@homesusa/layout";
import { AuthCompanyContext } from "@homesusa/auth";
import { getSidebarItems } from "../constants";

import CompanyCreate from "modules/company/pages/company-create.component";
import CompanyDetail from "modules/company/pages/company-detail.component";
import EmployeeDetail from "modules/company/pages/company-employee-detail.component";
import CompanyAddEmployee from "modules/company/pages/company-user-add.component";
import CorporationDetail from "modules/corporation/pages/corporation-detail.component";
import CompanySavedChangesDetails from "modules/company-history/pages/company-saved-changes-details.component";

const CompanyGrid = React.lazy(
  () => import("modules/company/pages/company-grid.component")
);
const CorporationGrid = React.lazy(
  () => import("modules/corporation/pages/corporation-grid.component")
);

function AppRoutes(): JSX.Element {
  const { appStateDispatch } = React.useContext(AppContext);
  const { currentCompany } = React.useContext(AuthCompanyContext);

  React.useEffect(() => {
    appStateDispatch({
      type: "UpdateSidebarItem",
      payload: getSidebarItems(currentCompany),
    });
  }, [currentCompany]);

  return (
    <Suspense fallback={<LinearProgress />}>
      <Routes>
        {appPublicRoutesConfig.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))}
        <Route
          path="/companies"
          element={
            <PrivateRoute
              allowedUserRoles={[RoleUser.MLSAdministrator, RoleUser.User]}
            />
          }
          errorElement={<ErrorBoundary />}
        >
          <Route
            element={
              <EmployeePrivateRoute
                allowedRoles={[
                  RoleEmployee.CompanyAdmin,
                  RoleEmployee.CompanyAdminReadonly,
                ]}
              />
            }
          >
            <Route path="new" element={<CompanyCreate />} />
            <Route path=":id" element={<CompanyDetail />} />
            <Route
              path=":id/company-saved-changes/:historyId"
              element={<CompanySavedChangesDetails />}
            />
            <Route path=":id/addEmployee" element={<CompanyAddEmployee />} />
            <Route
              path=":id/employees/:employeeId"
              element={<EmployeeDetail />}
            />
            <Route index element={<CompanyGrid />} />
          </Route>
        </Route>
        <Route
          path="/corporations"
          element={
            <PrivateRoute allowedUserRoles={[RoleUser.MLSAdministrator]} />
          }
          errorElement={<ErrorBoundary />}
        >
          <Route path=":id" element={<CorporationDetail />} />
          <Route index element={<CorporationGrid />} />
        </Route>
        <Route element={<PrivateRoute />}>
          {appPrivateRoutesConfig.map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
          ))}
        </Route>
        <Route path="/" element={<Navigate to="/companies" />} />
        <Route path="*" element={<p>not found</p>} />
      </Routes>
    </Suspense>
  );
}

export default AppRoutes;
