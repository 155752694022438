import React from "react";
import { FormPanel } from "@homesusa/form";
import { FormCompaniesTabs } from "../form-update/tabs/tab.component";
import { MlsComponent } from "../form-update/msl/mls.component";
import { PhotoServices } from "../form-update/photo-services.component";
import { CompanyContext } from "modules/company/contexts";
import { useHasRole } from "@homesusa/auth";
export const FormUpdateComponent = (): JSX.Element => {
  const {
    tabs: { selectedTab, setSelectedTab },
  } = React.useContext(CompanyContext);
  const { isMlsAdministrator } = useHasRole();
  return (
    <FormPanel
      tabs={["General", "MLS", "Photography Services"]}
      selectedTab={selectedTab}
      onChangeTab={(value: number): void => setSelectedTab(value)}
    >
      <FormPanel.TabContent index={0} selected={selectedTab}>
        <FormCompaniesTabs />
      </FormPanel.TabContent>
      <FormPanel.TabContent index={1} selected={selectedTab}>
        <MlsComponent />
      </FormPanel.TabContent>
      <FormPanel.TabContent index={2} selected={selectedTab}>
        <PhotoServices readOnly={!isMlsAdministrator} />
      </FormPanel.TabContent>
    </FormPanel>
  );
};
