import { Business as BusinessIcon } from "@mui/icons-material";

import { Company } from "@homesusa/core";
import { SidebarItem } from "@homesusa/layout";

export const getSidebarItems = (
  currentCompany?: Company
): Record<string, SidebarItem> => {
  return {
    ["companyManagement"]: {
      title: "Company",
      icon: BusinessIcon,
      items: !!currentCompany
        ? [
            {
              title: "Company Profile",
              endpoint: "/companies",
            },
          ]
        : [
            {
              title: "Company Profiles",
              endpoint: "/companies",
            },
            { title: "Corporations", endpoint: "/corporations" },
          ],
    },
  };
};
