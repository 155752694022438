import React from "react";
import { Grid, Typography } from "@mui/material";
import { Input, Switch, Select } from "@homesusa/form";
import { AnimatedContainer, SectionBox } from "core/components";
import { useLocalOptions } from "modules/company/hooks";
function FormTransferTab(): JSX.Element {
  const { protocolOptions } = useLocalOptions();
  return (
    <AnimatedContainer>
      <Typography sx={{ width: "100%", my: 3 }} variant="h2">
        File Transfer Protocol
      </Typography>
      <Grid container columnGap={3}>
        <Grid item xs={6}>
          <SectionBox title="FTP Senttings">
            <Grid container>
              <Grid item xs={6}>
                <Switch
                  label="Auto-FTP All Completed Photo Requests"
                  name="photoRequestInfo.autoFtp"
                />
              </Grid>
              <Grid item xs={6}>
                <Input label="FTP URL" name="photoRequestInfo.ftpUrl" />
              </Grid>

              <Grid
                item
                xs={6}
                sx={{ "& .MuiFormControl-root": { width: "100%" } }}
              >
                <Select
                  label="Transfer Protocol"
                  options={protocolOptions}
                  name="photoRequestInfo.transferProtocol"
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  label="FTP Username"
                  name="photoRequestInfo.ftpUsername"
                />
              </Grid>

              <Grid item xs={6}>
                <Input
                  label="FTP Password"
                  name="photoRequestInfo.ftpPassword"
                />
              </Grid>
            </Grid>
          </SectionBox>
        </Grid>
      </Grid>
    </AnimatedContainer>
  );
}

export default FormTransferTab;
