// components/company-form.tsx
import React from "react";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { Info } from "@mui/icons-material";

import {
  FormContext,
  Select,
  Switch,
  Input,
  FormStyled,
  NumberInput,
} from "@homesusa/form";
import { useHasRole } from "@homesusa/auth";
import { AnimatedContainer, SectionBox, DisplayImage } from "core/components";
import {
  CompanyContext,
  CompanyFormContextProps,
} from "modules/company/contexts";
import {
  toXMLBuilderNumber,
  xmlBuilderstoString,
} from "modules/company/utils/xml-builder.utils";
import { uploadLogo } from "core/services/companies/company.service";
import { ContactPhoneSection } from "../phones/contact-phone-section.component";
import { ContactsComponent } from "./contacts/contacts.component";
import { LockListingsSwitch } from "./lock-listings-switch.component";

function FormGeneralTab(): JSX.Element {
  const { formState, otherProps, formDispatch } =
    React.useContext<CompanyFormContextProps>(FormContext);

  const { company } = React.useContext(CompanyContext);
  const { isMlsAdministrator } = useHasRole();

  const handleChange = (value: string | number | undefined): void => {
    if (!formDispatch) return;
    if (typeof value === "number") value = value.toString();
    const xmlBuilderNumbers = toXMLBuilderNumber(value);
    formDispatch({
      type: "FormChangeByInputName",
      inputName: "xmlBuilderNumbers",
      payload: xmlBuilderNumbers,
    });
  };
  return (
    <AnimatedContainer>
      <FormStyled>
        <Typography sx={{ width: "100%", my: 3 }} variant="h2">
          Company Information
        </Typography>
        <form data-testid="company-form">
          <Grid container rowGap={3}>
            <Grid item xs={12}>
              <Grid container columnGap={3} wrap="nowrap">
                <Grid item xs={5}>
                  <SectionBox title="General">
                    <Grid container>
                      <Grid item xs={6}>
                        <Input name="name" />
                      </Grid>
                      <Grid item xs={6}>
                        <Select
                          name="corporationId"
                          options={otherProps ? otherProps.corporations : {}}
                          disabled={!isMlsAdministrator}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Select name="type" />
                      </Grid>
                      <Grid item xs={6}>
                        <Input label="Broker" name="brokerId" />
                      </Grid>
                      <Grid item xs={6}>
                        <Select name="market" />
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          name="webUrl"
                          regex="(http:\/\/|https:\/\/)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Input name="domain" />
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          label="XML Builder Numbers"
                          name="xmlBuilderNumbers"
                          defaultValue={xmlBuilderstoString(
                            company.xmlBuilderNumbers
                          )}
                          regex="^[a-zA-Z0-9_; -]+$"
                          regexMessage="Only numbers, letters and semicolon ( ; ) are allowed"
                          tooltip={
                            "You can add more than one Builder Number by delimiting them with a semicolon ( ; )"
                          }
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </SectionBox>
                </Grid>
                <Grid item xs={3}>
                  <SectionBox title="Logo">
                    <DisplayImage
                      defaultImage={formState?.logoUri}
                      handleChange={(image): void => {
                        uploadLogo(formState?.id, image);
                      }}
                    />
                  </SectionBox>
                </Grid>
                {isMlsAdministrator && (
                  <Grid item xs={4}>
                    <SectionBox title="Presentation & Display">
                      <Switch
                        label="Allow Photo Request Creation for Incomplete Homes"
                        name="settingInfo.allowPhotoRequestForIncompleteHomes"
                      />
                      <Switch
                        label="Hide Deleted Homes to List NOW Window"
                        name="settingInfo.displayDeletedListingsWindow"
                      />
                      <Switch
                        label="Hide Lead Manager Link"
                        name="settingInfo.hideLeadManager"
                      />
                      <Switch
                        label="Hide Realtor Reports Link"
                        name="settingInfo.showRealtorReports"
                      />
                      <Switch
                        label="Stop XML Media Sync of Existing Listings"
                        name="settingInfo.stopXMLMediaSyncOfExistingListings"
                      />
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Switch
                          label="Make Scraper Pull Only New Homes to List"
                          name="settingInfo.onlyPullHomesToList"
                        />
                        <Tooltip title="If switch is ON, Web Scraper will only create new homes to list and will never show us Plans or Communities, nor manage Listings, such as submitting to us list price changes">
                          <Info color="disabled" />
                        </Tooltip>
                      </Box>
                      <Switch
                        label="Prevent New Listing Creation"
                        name="settingInfo.allowNewListings"
                      />
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Switch
                          label="Show Selling Agent Internal Report"
                          name="settingInfo.showSellingAgentInternalReport"
                        />
                        <Tooltip title="Turn switch ON only for Private Label companies to allow employees to see the Selling Agent Internal Report.">
                          <Info color="disabled" />
                        </Tooltip>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Switch
                          label="Stop Completed Homes w/o Photos Emails"
                          name="settingInfo.stopCompletedHomesWithOutPhotosEmails"
                        />
                        <Tooltip title="If switch is ON, this company will no longer receive the Completed Homes without Photos emails.">
                          <Info color="disabled" />
                        </Tooltip>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Switch
                          label="Stop Violation Warning Alert Emails"
                          name="settingInfo.stopAlertEmails"
                        />
                        <Tooltip title="If switch is ON, this company will no longer receive the alert emails.">
                          <Info color="disabled" />
                        </Tooltip>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Switch
                          label="Hide Expired Date field"
                          name="settingInfo.hideExpiredDate"
                        />
                        <Tooltip title="If switch is ON, the  Expired date field is Hide.">
                          <Info color="disabled" />
                        </Tooltip>
                      </Box>
                      <LockListingsSwitch />
                    </SectionBox>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container columnGap={3} wrap="nowrap">
                <Grid item xs={5.72}>
                  <SectionBox title="Address">
                    <Grid container>
                      <Grid item xs={6}>
                        <NumberInput name="addressInfo.streetNum" />
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          label="Street Name"
                          name="addressInfo.streetName"
                          required
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Input name="addressInfo.city" />
                      </Grid>
                      <Grid item xs={6}>
                        <Select name="addressInfo.state" />
                      </Grid>
                      <Grid item xs={6}>
                        <NumberInput name="addressInfo.zip" />
                      </Grid>
                    </Grid>
                  </SectionBox>
                </Grid>
                <Grid item xs={5}>
                  <ContactsComponent />
                </Grid>
                <Grid item xs={3}>
                  {isMlsAdministrator && (
                    <Box>
                      <SectionBox title="QuickBooks Information">
                        <Grid container>
                          <Grid item xs={12}>
                            <NumberInput
                              label="Customer Reference"
                              name="customerRef"
                            />
                          </Grid>
                        </Grid>
                      </SectionBox>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ContactPhoneSection />
            </Grid>
          </Grid>
        </form>
      </FormStyled>
    </AnimatedContainer>
  );
}

export default FormGeneralTab;
